<template>
  <div>
    <ConferenceDigitalComp v-bind:mobile="mobile"/>
  </div>
</template>

<script>
import ConferenceDigitalComp from '@/components/desktop/ConferenceFormation/ConferenceDigitalComp'

  export default {
    name: 'ConferenceSportHealth',
    props: ['mobile'],
    components: {
      ConferenceDigitalComp,
    },
  }
</script>
