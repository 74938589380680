<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionBlueMobile' : 'sectionBlue'" no-gutters>
      <v-col cols="6" v-if="!mobile">
        <v-img
            class="imgSectionLeft"
            :src="require('@/assets/conferenceFormation/conference6.png')"
            contain
            height="375"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textRight textBlue2'" v-html="$t('textFirstSection1')"></p>
        <p v-bind:class="mobile ? 'textMobile textGrey' : 'textRight textGrey'" v-html="$t('textFirstSection2')"></p>
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textRight textBlue2'" v-html="$t('textFirstSection3')"></p>
        <v-img
            v-if="mobile"
            :src="require('@/assets/conferenceFormation/conference6.png')"
            contain
            height="250"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionWhiteMobile' : 'sectionWhite'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textLeft textBlue2'" v-html="$t('textSecondSection')"></p>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <v-img
            v-bind:class="mobile ? '' : 'imgSectionRight'"
            :src="require('@/assets/conferenceFormation/conference7.png')"
            contain
            v-bind:height="mobile ? 250 : 375"
        />
      </v-col>
    </v-row>
    <v-row justify="center" v-bind:class="mobile ? 'sectionBlueMobile1' : 'sectionBlue'" no-gutters>
      <v-col cols="6" v-if="!mobile">
        <v-img
            class="imgSectionLeft"
            :src="require('@/assets/conferenceFormation/conference5.png')"
            contain
            height="375"
        />
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textRight textBlue2'" v-html="$t('textThirdSection1')"></p>
        <p v-bind:class="mobile ? 'textMobile textGrey' : 'textRight textGrey'" v-html="$t('textThirdSection2')"></p>
        <p v-bind:class="mobile ? 'textMobile textBlue2' : 'textRight textBlue2'" v-html="$t('textThirdSection3')"></p>
        <v-img
            v-if="mobile"
            :src="require('@/assets/conferenceFormation/conference5.png')"
            contain
            height="250"
        />
      </v-col>
    </v-row>
    <v-row justify="center" align="center" v-bind:class="mobile ? 'sectionWhiteMobile' : 'sectionWhite'" no-gutters>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <h3 v-bind:class="mobile ? 'textMobileLast textBlue2' : 'textLeft textBlue2'" v-html="$t('textFourthSection')"></h3>
      </v-col>
      <v-col v-bind:cols="mobile ? 10 : 6">
        <v-img
            v-bind:class="mobile ? '' : 'imgSectionRight'"
            :src="require('@/assets/conferenceFormation/conference8.png')"
            contain
            v-bind:height="mobile ? 250 : 375"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'ConferenceDigitalComp',
    props: ['mobile'],
    data: () => ({
    }),
  }
</script>

<i18n>
{
  "en": {
    "mainTitle": "Our digital conferences",
    "textFirstSection1": "<br> <strong> The basics of tablets and smartphones </strong> <br> <br>",
    "textFirstSection2": "With the evolution of technologies and the dematerialization of documents, it becomes more and more important to be comfortable with digital tools. In addition, confinement has shown us the importance of mastering these tools to maintain as much as possible the connection with our loved ones. <br> <br> The objective of this conference is to provide you with the basics as well as practical tips for using your smartphones and digital tablets.",
    "textFirstSection3": "Bring your device during the conference to complete the practical exercises at the same time as the speaker.",
    "textSecondSection": "<br> <br> <strong> Browse the internet </strong> <br> <br> Do you know how to repair your coffee machine? Why do we have goose bumps? Do you know how to meditate? or do you know the latest Nobel prizes? <br> <br> <strong> All of the above, and more, can be found on the internet. </strong> <br> Your internet is an almost inexhaustible source of information and training, but do you still have to know how and where to look? A specialist in digital tools will answer this question and give you all his tips that will make your daily life easier.",
    "textThirdSection1": "<br> <br> <strong> Protect your data and avoid scams </strong>",
    "textThirdSection2": "The Internet is today an indispensable tool in everyday life. <br> Unfortunately, it is also open to a lot of malicious people who go out of their way to rip you off.",
    "textThirdSection3": "How to protect yourself from an anarchist? How can you be sure that a purchase is made safely? How do I protect my accounts and my data? <br> A specialist in digital tools will answer all these questions during this conference.",
    "textFourthSection": "<strong> Keep in touch with your loved ones! </strong>"
  },
  "fr": {
    "mainTitle": "Nos conférences sur le numérique",
    "textFirstSection1": "<br><strong>Les bases des tablettes et smartphones</strong><br><br>",
    "textFirstSection2": "Avec l’évolution des technologies et la dématérialisation des documents, cela devient de plus en plus important d’être à l’aise avec les outils numériques. De plus, le confinement nous a prouvé l’importance de maîtriser ces outils pour maintenir le plus possible le lien avec nos proches. <br><br> L’objectif de cette conférence est de vous apporter les bases ainsi que des astuces bien pratiques dans l’utilisation de vos smartphones et tablettes numériques.",
    "textFirstSection3": "Munissez-vous de votre appareil pendant la conférence pour réaliser les exercices pratiques en même temps que l’intervenant.",
    "textSecondSection": "<br><br><strong>Naviguer sur internet </strong> <br><br> Savez-vous comment réparer votre machine à café ? Pourquoi avons-nous la chair de poule ? Savez-vous méditer ? Ou encore connaissez-vous les derniers prix Nobel ? <br><br> <strong> Toutes ces réponses, et bien plus encore, se trouvent sur internet. </strong><br> Votre internet est une source d’information et de formation presque inépuisable, mais encore faut-il savoir comment et où chercher ? Un spécialiste des outils numériques répondra à cette question et vous donnera toutes ses astuces qui faciliteront votre quotidien.",
    "textThirdSection1": " <br><br> <strong>Protéger ses données et éviter les arnaques </strong>",
    "textThirdSection2": "Internet est aujourd’hui un outil indispensable au quotidien. <br> Malheureusement, il est également ouvert à beaucoup de personnes malveillantes qui s’adonnent à beaucoup de malice pour vous arnaquer.",
    "textThirdSection3": "Comment se prémunir d’une anarque ? Comment être sûr qu’un achat est réalisé en toute sécurité ? Comment protéger mes comptes et mes données ? <br> Un spécialiste des outils numériques, répondra à toutes ces questions durant cette conférence.",
    "textFourthSection": "<strong>Garder le contact avec vos proches !</strong>"
  }
}
</i18n>

<style scoped>

.firstSection {
  padding-top: 20px;
  padding-bottom: 50px;
  color: #808080;
}

.leftPart {
  padding-left: 200px;
  padding-right: 50px;
}

.rightPart {
  padding-left: 50px;
  padding-right: 200px;
}

.textMobile {
  font-size: 14px;
}

.textMobileLast {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
  font-size: 16px;
}

.textBlue {
  color: #1579A7;
}

.textBlue2 {
  color: #57939E;
}

.textGrey {
  color: #808080;
}

.sectionBlue {
  padding: 50px;
  background-color: #D5EEF4;
}

.sectionBlueMobile {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #D5EEF4;
}

.sectionBlueMobile1 {
  padding-bottom: 40px;
  background-color: #D5EEF4;
}

.sectionWhiteMobile {
  padding-bottom: 40px;
}

.sectionWhite {
  padding: 50px;
}

.textRight {
  padding-right: 250px;
}

.textLeft {
  padding-right: 50px;
  padding-left: 200px;
}

.imgSectionLeft {
  margin-left: 75px;
}

.imgSectionRight {
  margin-right: 100px;
}

</style>
